.contentWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.mosaicWrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    width: 80%;
}

.text-left {
    text-align: left;
}
