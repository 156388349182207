.schedulePlayStateContainer {
  display: flex;
  flex-direction: row;
}

.schedulePlayStateContainer svg {
  margin-right: 10px;
}

.tooltipContainer:hover .tooltip {
  visibility: visible;
}

.tooltipContainer {
  position: relative;
}

.tooltip {
  visibility: hidden;

  background-color: black;
  color: #fff;
  text-align: left;

  border-radius: 6px;
  padding: 10px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: 100%;
  margin-top: 20px;

  position: absolute;
  left: 46%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.tooltip p, .tooltip b {
  white-space: nowrap;
}

.tooltip::before {
  content: '';
  position: absolute;
  display: block;
  width: 0px;
  left: 50%;
  top: 0;
  border: 15px solid transparent;
  border-top: 0;
  border-bottom-color: black;
  transform: translate(-50%, calc(-100% + 1px));
}
