.inputWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}
.input {
    padding-left: 6px;
    width: 100%;
    height: 44px;
    font-size: 20px;
}