.presetLabel {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
}

.badge.default {
    background-color: #6c757d!important
}

.badge.low {
    background-color: #ffc107;
}
.badge {
    background-color: #343a40;
}
