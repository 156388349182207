.channelThumbnail {
    min-width: 500px;
}

.resultsWrapper {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;

    font-size: 12px;
    overflow-x: auto;
}

.resultsRow {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 90px;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 50px;
}

.resultsRow>* {
    padding: 12px;
}

.resultsRow p {
    font-size: 0.7rem;
}

.resultThumbnail {
    width: 80px;
    height: 45px;
    background-size: cover;
}

.headerRow {
    border-bottom: 2px solid rgb(222, 226, 230);
    border-top: 2px solid rgb(222, 226, 230);
}

.image {
    aspect-ratio: 16/9;
    width: 100%;
    max-width: 640px;
}

.iconLegend {
    width: max-content;
    margin: 15px;
}

.iconLegend table {
    margin: auto;
    width: auto;

    margin-top: 10px;
    padding-left: 5px;
    z-index: 2;
    position: absolute;
    background-color: #fff;
    box-shadow: 0px 5px 10px 5px rgba(0, 0, 0, 0.4);
}

.iconLegend table .iconCell {
    text-align: center;
}

.resultsTable {
    box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
}

.resultsTableHeader {
    position: sticky;
    top: 0;
    background-color: #fff;
    box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
}

.indicationRow {
    cursor: pointer;
}

.ad-icon {
    height: 1.5em;
    width: 1.5em;
}

.sportsWrapper {
    margin-top: 25px;
}

.sportsList {
    width: 75%;
}

.sportAlarmPresets {
    margin: 15px;
    width: 225px;
}

.alarmPreset {
    text-align: left;
}
