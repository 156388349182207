.navBar {
    background-color: #282c34;
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-size: calc(10px + 2vmin);
    color: white;
}
.navBar.olympics {
    background-color: #e5e5e5;
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-size: calc(10px + 2vmin);
    color: white;
}

.navItem {
    padding: 10px;
    display: flex;
}

.logo {
    height: 40px;
    pointer-events: none;
    padding-left: 30px;
    padding-right: 30px;
}


.olympics .logo {
    height: 40px;
    pointer-events: none;
    padding-left: 0px !important;
}

.olympicsLogo {
    height: 80px;
    pointer-events: none;
    padding-left: 30px;
}

.navBar a {
    text-decoration: none;
    color: #a0a0a0;
}

.navBar a.active {
    color: white;
}

header.navBar.olympics a {
    text-decoration: none;
    color: #000!important;
}

header.navBar.olympics a.active {
    color: #6c6c6c!important;
}
