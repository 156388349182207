.modalBackdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0 , 0, 0.5);
    overflow: scroll;
}

.detailModal {
    max-width: 80vw;
    background: white;
    border: 1px solid #ccc;

    top: 15px;
    position: absolute;
    padding: 15px;
    overflow: auto;
}

.imageLoaderImage {
    margin-bottom: 12px;
    background-size: 100% 100%;
    height: 100%;
    width: 100%;
}

.closeButton {
    position: absolute;
    cursor: pointer;
    right: 14px;
    color: grey;
    width: 28px;
    height: 28px;
}

.detailModal .imageLoaderImage {
    height: 300px;
    background-size: cover;
}

.detailModal .spectrumImagesContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 12px;
}

.spectrumImagesContainer div:first-of-type {
    margin-right: 5px;
}

.spectrumImagesContainer div:last-of-type {
    margin-left: 5px;
}

.detailModal .spectrumImage {
    height: 192px;
}

.detailModal .thumbnail {
    margin-top: 15px;
    max-height: 50vh;
}

.modalContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    padding: 40px;
}

.detailsSection {
    display: flex;
    flex-direction: row;
    margin-left: 25px;
}

.detailsSectionColumn {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    flex-direction: column;
}

.detailsSectionColumn p {
    display: flex;
    align-items: center;
    height: 44px;
    border-bottom: 1px solid;
    width: 100%;
    text-align: left;
}

.detailsSectionColumn:first-of-type p {
    padding-left: 8px;
    font-weight: bold;
    padding-right: 8px;
}