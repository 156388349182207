.searchContentWrapper {
    width: 75%;
}

.searchHeaderWrapper {
    margin-bottom: 25px;
}

.searchHeaderRow {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
}

.searchHeaderTitle {
    text-align: left;
    margin-bottom: 25px;
}

.searchError {
    color: red;
}

.selectedType {
    background-color: #a0a0a0;
}

.searchTypeButtons {
    padding-top: 5px;
    padding-bottom: 15px;
}

.buttonWrapper {
    width: 200px;
    display: flex;
    justify-content: space-between;
    padding-right: 15px;
}

.timeWrapper {
    padding-top: 15px;
    width: 400px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.searchBarWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 40%;
}

.searchInputWrapper {
    width: 100%;
    padding-right: 15px;
}

.searchBarWrapper:first-child {
    margin-right: 5px;
}

.searchResultsWrapper {
    padding-bottom: 50px;
}

.searchResultsHeader {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 2fr 2fr 5fr;
    align-items: center;
    margin-bottom: 20px;
    min-height: 50px;

    border-top: 2px solid rgb(222, 226, 230);
    box-shadow: 0 6px 2px -2px hsla(0, 0%, 0%, 0.2);
}

.searchResultRow {
    padding-top: 10px;
    padding-bottom: 10px;
    min-height: 50px;
    border-bottom: 2px solid rgb(222, 226, 230);
    cursor: pointer;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr 2fr 2fr 5fr;
}

.searchResultRow:hover {
    background-color: rgba(0, 0, 0, 0.075);
}

.searchResultColumn {
    padding-left: 15px;
    padding-right: 15px;
    word-wrap: anywhere;
}

.searchPaginationWrapper {
    padding-top: 15px;
}