.filterBar {
    padding: 15px;
}

.filterItemsWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-left: 25px;
    padding-right: 25px;
}

.filterItemWrapper {
    padding: 5px;
    cursor: pointer;
}

.filterItem span.badge {
    border-radius: 0.0rem !important;
}
