.mosaicChannelStatusBar {
    justify-self: flex-end;
    width: 80%;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 5px;
}

.statusContainer {
    padding-left: 2px;
    padding-right: 2px;
}