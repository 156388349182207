body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}
h3, h4, h5, h6, h2, h1 {
  font-weight: 300;
}
a {
  color: white;
  font-size: 1.4rem;
  font-weight: bold;
  text-decoration: none;
}

p {
  margin: 0;
  font-size: 0.9rem;
}

.App {
  text-align: center;
}

* {
  box-sizing: border-box;
}

.lead {
  font-size: 1.25rem;
}

.pageWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 20px;
}

.errorColor {
  color: #dc3545;
}

.infoColor {
  color: grey;
}

.infoColorWhite {
  color: white;
}

.correctColor {
  color: #28a745;
}

.logoutWrapper{
  position: absolute;
  top: 0;
  right: 0;
  height: 60px;
  background-color: #ff9900;
  display: flex;
  justify-content: center;
  align-items: center;
}



.page {
  -webkit-animation: fadein 0.5s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 0.5s; /* Firefox < 16 */
  -ms-animation: fadein 0.5s; /* Internet Explorer */
  -o-animation: fadein 0.5s; /* Opera < 12.1 */
  animation: fadein 0.5s;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}


@media (min-width: 1800px) {
  .col-xxxl-3 {
    width: 25%;
  }
}

@media (min-width: 2400px) {
  .col-xxxxl-2 {
    width: 20%;
  }
}
