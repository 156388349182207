.mosaicChannelContainerWrapper {
    padding: 5px;
}

.mosaicChannelContainer {
    position: relative;
    cursor: pointer;
    background-color: rgb(108, 117, 125);
    aspect-ratio: 16/9;
    border: 1px solid #fff;
}

.mosaicChannelContainer::before {
    float: left;
    padding-top: 56.25%;
    content: '';
}
.mosaicChannelContainer::after {
    display: block;
    content: '';
    clear: both;
}

.mosaicChannelContainerWrapper:hover {
    z-index: 999;

}

.mosaicChannelContainer:hover {
    transform: scale(1.2);
    cursor: pointer;
    border: 1px solid #fff;
}

.channelErrorHighlight {
    border: 4px solid red !important;
}

.mosaicChannelOverlay {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    height: 100%;
    position: relative;
}

.mosaicChannelContainerImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.mosaicChannelTopbar {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    padding-left: 10px;
    padding-right: 10px;
    color: white;
}

.mosaicChannelTopbar .schedulePlayStateContainer {
    position: absolute;
    top: 36px;
    right: 7px;

}

.mosaicChannelLabel {
    /* -webkit-text-stroke: 1px black;*/
    /*font-weight: bold;*/
    font-size: 15px;
    text-align: left;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    /*display: inline;*/
    padding: 5px;

    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
}
.mosaicChannelHeader{
    font-size: 20px;
    font-weight: bold;

}

.mosaicChannelLiveStatus {
    padding-left: 8px;
    padding-right: 8px;
    margin-bottom: 35px;
    font-size: 16px;
    position: absolute;
    top: 10px;
    right: 12px;
}


.mosaicChannelTooltip {
    visibility: hidden;

    background-color: black;
    color: #fff;
    text-align: left;

    border-radius: 6px;
    padding: 10px;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    top: 100%;
    margin-top: 20px;

    width: 160px;
    left: 50%;
    margin-left: -85px;
}

.mosaicChannelTooltip::before {
    content: '';
    position: absolute;
    display: block;
    width: 0px;
    left: 50%;
    top: 0;
    border: 15px solid transparent;
    border-top: 0;
    border-bottom-color: black;
    transform: translate(-50%, calc(-100% + 1px));
}

.mosaicChannelContainer:hover .mosaicChannelTooltip {
    visibility: visible;
}

.mosaicChannelTooltip p {
    margin-bottom: 5px;
    font-size: 0.70rem;
}
